

const Testi = [
    {
      "id": 1,
      "name": "Natura Major",
      "description": "2014, öljyväri kankaalle, 145x180 cm",
      "imageAdress": require('../natura/Natura Major.jpeg')
    },
    {
      "id": 2,
      "name": "Natura Mare",
      "description": "2013, öljyväri kankaalle, 100x130 cm",
      "imageAdress": require('../natura/Natura Mare.jpeg')
    },
    {
      "id": 3,
      "name": "Natura Imago",
      "description": "2014, öljyväri kankaalle, 100x130 cm",
      "imageAdress": require('../natura/Natura Imago.jpeg')
   },
   {
      "id": 4,
      "name": "Natura Lux",
      "description": "2013, öljyväri kankaalle, 100x130 cm",
      "imageAdress": require('../natura/Natura Lux.jpeg')
   },
   {
      "id": 5,
      "name": "Chronos",
      "description": "2014, öljyväri kankaalle, 100x130 cm",
      "imageAdress": require('../natura/Chronos.jpeg')
   },
   {
      "id": 6,
      "name": "Natura Borealis",
      "description": "2013, öljyväri kankaalle, 100x130 cm",
      "imageAdress": require('../natura/Natura_Borealis.jpg')
   },
  
   {
      "id": 7,
      "name": "Illusio",
      "description": "2013, 72x100cm, litografia",
      "imageAdress": require('../natura/Illusio.jpeg')
   },
   {
      "id": 8,
      "name": "Bios",
      "description": "2012, 74x74cm, litografia",
      "imageAdress": require('../natura/Bios.jpeg')
   },
   {
      "id": 9,
      "name": "Gaia",
      "description": "2012, 74x74cm, litografia",
      "imageAdress": require('../natura/Gaia.jpeg')
   },
   {
      "id": 10,
      "name": "Kaiku",
      "description": "2012, 72x100cm, litografia",
      "imageAdress": require('../natura/Kaiku.jpeg')
   },
   {
      "id": 11,
      "name": "Luonnontila",
      "description": "2013, 74x74cm, litografia",
      "imageAdress": require('../natura/Luonnontila.jpeg')
   },
   {
      "id": 12,
      "name": "Ikaros",
      "description": "2012, 74x74cm, litografia",
      "imageAdress": require('../natura/Ikaros.jpeg')
   },
   {
      "id": 13,
      "name": "Mirare",
      "description": "2013, öljyväri levylle, 61x88 cm",
      "imageAdress": require('../natura/Mirare.jpeg')
   },
   {
      "id": 14,
      "name": "Crepuscule",
      "description": "2013, öljyväri levylle, 61x88 cm",
      "imageAdress": require('../natura/Crepuscule.jpeg')
   },
   {
      "id": 15,
      "name": "Nox",
      "description": "2013, öljyväri levylle, 61x88 cm",
      "imageAdress": require('../natura/Nox.jpeg')
   },
   {
      "id": 16,
      "name": "Macro",
      "description": "2014, öljyväri levylle, 61x88 cm",
      "imageAdress": require('../natura/Macro.jpeg')   
   },
   {
      "id": 17,
      "name": "Galleria Bronda 1",
      "description": "Natura - Näyttely, 2014, Helsinki",
      "imageAdress": require('../natura/Galleria Bronda 1.jpeg')   
   },
   {
      "id": 18,
      "name": "Galleria Bronda 2",
      "description": "Natura - Näyttely, 2014, Helsinki",
      "imageAdress": require('../natura/Galleria Bronda 2.jpeg')   
   },
   {
      "id": 19,
      "name": "Galleria Bronda 1",
      "description": "Natura - Näyttely, 2014, Helsinki",
      "imageAdress": require('../natura/Galleria Bronda 3.jpeg')   
   },
   {
      "id": 20,
      "name": "Galleria Bronda 1",
      "description": "Natura - Näyttely, 2014, Helsinki",
      "imageAdress": require('../natura/Galleria Bronda 4.jpeg')   

}
   
]


export default Testi;