

const Testi = [
    {
      "id": 1,
      "name": "In Summa",
      "description": "2015, öljyväri kankaalle, 167x191 cm",
      "imageAdress": require('../in_summa/insumma.jpeg')
    },
    {
      "id": 2,
      "name": "Unity",
      "description": "2015, öljyväri kankaalle, 100x130 cm",
      "imageAdress": require('../in_summa/unity.jpeg')
    },
    {
      "id": 3,
      "name": "In Situ",
      "description": "2015, öljyväri kankaalle, 110x120 cm",
      "imageAdress": require('../in_summa/insitu.jpeg')
   },
   {
      "id": 4,
      "name": "Isola",
      "description": "2015, öljyväri kankaalle, 100x130 cm",
      "imageAdress": require('../in_summa/isola.jpeg')
   },
   {
      "id": 5,
      "name": "Indigo",
      "description": "2014, öljyväri kankaalle, 100x130 cm",
      "imageAdress": require('../in_summa/indigo.jpeg')
   },
   {
      "id": 6,
      "name": "Genius Loci I",
      "description": "2015, öljyväri kankaalle, 50x40 cm",
      "imageAdress": require('../in_summa/gl1.jpeg')
   },
   {
      "id": 7,
      "name": "Genius Loci III",
      "description": "2015, öljyväri kankaalle, 50x40 cm",
      "imageAdress": require('../in_summa/gl3.jpeg')
   },
   {
      "id": 8,
      "name": "Genius Loci IV",
      "description": "2015, öljyväri kankaalle, 50x40 cm",
      "imageAdress": require('../in_summa/gl4.jpeg')
   },
   {
      "id": 9,
      "name": "Genius Loci II",
      "description": "2015, öljyväri kankaalle, 50x40 cm",
      "imageAdress": require('../in_summa/gl2.jpeg')
   },
   {
      "id": 10,
      "name": "Genius Loci V",
      "description": "2015, öljyväri kankaalle, 50x40 cm",
      "imageAdress": require('../in_summa/gl5.jpeg')
   },
   {
      "id": 11,
      "name": "Camouflage",
      "description": "2015, öljyväri kankaalle, 50x40 cm",
      "imageAdress": require('../in_summa/camouflage.jpeg')
   },
   {
      "id": 12,
      "name": "Mirage",
      "description": "2014, öljyväri kankaalle, 41x33 cm",
      "imageAdress": require('../in_summa/mirage1.jpeg')
   },
   {
      "id": 13,
      "name": "Escapist",
      "description": "2015, litografia, 54x60 cm",
      "imageAdress": require('../in_summa/escapist.jpeg')
   },
   {
      "id": 14,
      "name": "Existentialist",
      "description": "2015, litografia, 54x60 cm",
      "imageAdress": require('../in_summa/existentialist.jpeg')
   },
   {
      "id": 15,
      "name": "Theia",
      "description": "2015, litografia, 42x38 cm",
      "imageAdress": require('../in_summa/theia.jpeg')
   },
   {
      "id": 16,
      "name": "Illusionist",
      "description": "2015, litografia, 54x60 cm",
      "imageAdress": require('../in_summa/illusionist.jpeg')   
   },
   {
      "id": 17,
      "name": "Galleria Bronda 1",
      "description": "In Summa -Näyttely, 2016, Helsinki",
      "imageAdress": require('../in_summa/bronda_1.jpeg')   
   },
   {
      "id": 18,
      "name": "Galleria Bronda 2",
      "description": "In Summa -näyttely, 2016, Helsinki",
      "imageAdress": require('../in_summa/bronda_2.jpeg')   
   },
   {
      "id": 19,
      "name": "Galleria Bronda 3",
      "description": "In Summa -näyttely, 2016, Helsinki",
      "imageAdress": require('../in_summa/bronda_3.jpeg')   
   },
   {
      "id": 20,
      "name": "Galleria Bronda 4",
      "description": "In Summa -näyttely, 2016, Helsinki",
      "imageAdress": require('../in_summa/bronda_4.jpeg')   

}
   
]


export default Testi;