

const Testi = [
    {
      "id": 1,
      "name": "Ornithology I",
      "description": "2017, tempera, 100x130 cm",
      "imageAdress": require('../diversity/ornithology1.jpeg')
    },
    {
      "id": 2,
      "name": "Encounter",
      "description": "2017, tempera, 100x130 cm",
      "imageAdress": require('../diversity/encounter.jpeg')
    },
    {
      "id": 3,
      "name": "Invertebrate",
      "description": "2017, tempera, 100x130 cm",
      "imageAdress": require('../diversity/invertebrate.jpeg')
   },
   {
      "id": 4,
      "name": "Ornithology II",
      "description": "2017, tempera, 100x130 cm",
      "imageAdress": require('../diversity/ornithology2.jpeg')
   },
   {
      "id": 5,
      "name": "Aarni",
      "description": "2017, tempera, 100x130 cm",
      "imageAdress": require('../diversity/aarni.jpeg')
   },
   
  
   {
      "id": 6,
      "name": "They see us IV",
      "description": "2018, tempera, 81x61 cm",
      "imageAdress": require('../diversity/they_see_us_IV.jpeg')
   },
   {
      "id": 7,
      "name": "They see us III",
      "description": "2017, tempera, 81x61 cm",
      "imageAdress": require('../diversity/they_see_us_3.jpeg')
   },
   {
      "id": 8,
      "name": "They see us I",
      "description": "2017, tempera, 81x61 cm",
      "imageAdress": require('../diversity/they_see_us1.jpeg')
   },
   {
      "id": 9,
      "name": "They see us II",
      "description": "2017, tempera, 81x61 cm",
      "imageAdress": require('../diversity/they_see_us_II.jpeg')
   },
   {
      "id": 10,
      "name": "They see us V",
      "description": "2018, tempera, 81x61 cm",
      "imageAdress": require('../diversity/they_see_us_v.jpeg')
   },
   {
      "id": 11,
      "name": "Codex Naturae VI",
      "description": "2016, tempera, 40x61 cm",
      "imageAdress": require('../diversity/codex_naturae_VI.jpeg')
   },
   {
      "id": 12,
      "name": "Codex Naturae IV",
      "description": "2017, tempera, 40x61 cm",
      "imageAdress": require('../diversity/codex_naturae4.jpeg')
   },
   {
      "id": 13,
      "name": "Codex Naturae V",
      "description": "2017, tempera, 40x61 cm",
      "imageAdress": require('../diversity/codex_naturae5.jpeg')
   },
   {
      "id": 14,
      "name": "Codex Naturae I",
      "description": "2016, tempera, 40x61 cm",
      "imageAdress": require('../diversity/codex_naturae1.jpeg')
   },
   {
      "id": 15,
      "name": "Verde I",
      "description": "2017, tempera, 81x61 cm",
      "imageAdress": require('../diversity/verde_I.jpeg')
   },
   {
      "id": 16,
      "name": "Verde II",
      "description": "2017, tempera, 81x61 cm",
      "imageAdress": require('../diversity/verde2.jpeg')   
   },
   {
      "id": 17,
      "name": "Vista II",
      "description": "2017, tempera, 41x61 cm",
      "imageAdress": require('../diversity/vista_II.jpeg')   
   },
   {
      "id": 18,
      "name": "Vista III",
      "description": "2017, tempera, 41x61 cm",
      "imageAdress": require('../diversity/vista_III.jpeg')   
   },
   {
      "id": 19,
      "name": "Vista I",
      "description": "2017, tempera, 41x61 cm",
      "imageAdress": require('../diversity/vista_I.jpeg')   
   },
   {
      "id": 20,
      "name": "Diversity",
      "description": "2018, tempera, 100x130 cm",
      "imageAdress": require('../diversity/diversity.jpeg')   
   },
   {
      "id": 21,
      "name": "Galleria Bronda 1",
      "description": "Diversity -näyttely, 2018, Helsinki",
      "imageAdress": require('../diversity/bronda1.jpeg')   
   },
   {
      "id": 22,
      "name": "Galleria Bronda 2",
      "description": "Diversity -näyttely, 2018, Helsinki",
      "imageAdress": require('../diversity/bronda2.jpeg')   
   },
   {
      "id": 23,
      "name": "Galleria Bronda 3",
      "description": "Diversity -näyttely, 2018, Helsinki",
      "imageAdress": require('../diversity/bronda3.jpeg')   
   },
   {
      "id": 24,
      "name": "Galleria Bronda 4",
      "description": "Diversity -näyttely, 2018, Helsinki",
      "imageAdress": require('../diversity/bronda4.jpeg')
}
   
]


export default Testi;