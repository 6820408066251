

const Testi = [
    {
      "id": 1,
      "name": "Et Cetera",
      "description": "2008, akryyliväri, 140x120 cm",
      "imageAdress": require('../et_cetera/etcetera.jpeg')
    },
    {
      "id": 2,
      "name": "Mandala",
      "description": "2009, akryyli- ja öljyväri, 125x105 cm",
      "imageAdress": require('../et_cetera/mandala.jpeg')
    },
    {
      "id": 3,
      "name": "Sarabande",
      "description": "2008, akryyliväri, 120x250 cm",
      "imageAdress": require('../et_cetera/Sarabande.jpeg')
   },
   {
      "id": 4,
      "name": "Alussa",
      "description": "2008, akryyliväri, 80x120 cm",
      "imageAdress": require('../et_cetera/alussa.jpeg')
   },
   {
      "id": 5,
      "name": "Vita Somnium Breve",
      "description": "2009, akryyliväri, 55x70 cm",
      "imageAdress": require('../et_cetera/vsb.jpeg')
   },
   {
      "id": 6,
      "name": "Samsara",
      "description": "2010, litografia, 63x73 cm",
      "imageAdress": require('../et_cetera/samsara.jpeg')
   },
   {
      "id": 7,
      "name": "Sykli",
      "description": "2010, litografia, 48x48 cm",
      "imageAdress": require('../et_cetera/sykli.jpeg')
   },
   {
      "id": 8,
      "name": "Preludi",
      "description": "2010, litografia, 48x48 cm",
      "imageAdress": require('../et_cetera/preludi.jpeg')
   },
   {
      "id": 9,
      "name": "Stamina",
      "description": "2017, 29x43 cm, litografia",
      "imageAdress": require('../et_cetera/stamina.jpeg')
   },
   {
      "id": 10,
      "name": "Taiga",
      "description": "2013, öljyväri, 61x88 cm",
      "imageAdress": require('../et_cetera/taiga.jpeg')
   },
   {
      "id": 11,
      "name": "Nocturne",
      "description": "2010, öljyväri, 145x180 cm",
      "imageAdress": require('../et_cetera/nocturne.jpeg')
   },
   {

      "id": 12,
      "name": "Syntax",
      "description": "2012, akryyliväri, 30x40 cm",
      "imageAdress": require('../et_cetera/syntax.jpeg')
   },
   {
      "id": 13,
      "name": "Ego",
      "description": "2012, akryyliväri, 70x61 cm",
      "imageAdress": require('../et_cetera/Ego.jpeg')
   },
   {
      "id": 14,
      "name": "Never Never",
      "description": "2016, akryyliväri, 81x100 cm",
      "imageAdress": require('../et_cetera/never_never.jpeg')
   },
   {
      "id": 15,
      "name": "Live Life",
      "description": "2016, akryyliväri, 90x105 cm",
      "imageAdress": require('../et_cetera/live_life.jpeg')

}
   
]


export default Testi;