
import React from 'react'

import 'react-image-lightbox/style.css';
import Helmet from 'react-helmet';

import Haloo from './testiKuva';

import Testi from '../in_summa/in_summa';

import '../component/Lightbox.css'
import '../component/image1.css'


class inSumma extends React.Component {
    
   

    render() {
      
     

        return(
            <div>

            <Helmet>
              <title>In Summa - Pekka Parviainen</title>
              <meta name="description" content="Sanotaan, että vain ihmisellä on luontosuhde, kaikki muut lajit ovat osa luontoa. Vaikka koemme erillisyyttä, olemme riippuvaisia luonnon tasapainosta." ></meta>
              <meta name="Keyword" content="In Summa Pekka Parviaianen" ></meta>
            </Helmet>
            <br></br>
            <h3 className="text-center">In Summa</h3>
            <hr></hr>
           
            <ul className="works">
               <li><a href="/home/exhibitions/natura_lux">Natura</a></li>

               <li><a href="/home/exhibitions/diversity">Diversity</a></li>
               <li><a href="/home/exhibitions/existence">Existence</a></li>
               <li><a href="/home/exhibitions/etcetera">Et_Cetera</a></li>


            </ul>
            <br></br>
           
             <Haloo 
                 kuvat={Testi}
                 nimi={'Maalaukset'}
             />
             
             
            
             
              <br></br>
             </div>



        )
    }
}

export default inSumma;