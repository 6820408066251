import React from 'react'
//import image1 from '../imagesFronpage/image2.jpg'

import image1 from '../imagesFronpage/pekkaBio.jpeg'
//import image2 from '../maalaukset/Encounter_web.jpg'
import './AboutMe.css';
//import { Controller, Scene } from 'react-scrollmagic';
import styled from 'styled-components';

class AboutMe extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            fin: true
        }
    }
    vaihada = () => {
       this.setState({ fin: false })
       
      
    }
    
    



    render() {

        const SectionWipesStyled = styled.div`
        overflow: hidden;
        .panel {
          height: 100vh;
          width: 100%;
          text-align: center;
        }
        
        .panel span {
          position: relative;
          display: block;
          overflow: visible;
          top: 50%;
          font-size: 80px;
        }
        
        .panel.blue {
          
          
          
          
        }
       
        
        .panel.turqoise {
          
          background-color: #38ced7;
        }
        
        .panel.green {
          background-color: #FFFFFF;
          height: 100vh
        }
        
        .panel.bordeaux {
          background-color: #953543;
        }
        .panel.white {
          background-color: #FFFFFF;
          height: auto;
          /*
          background-position: center center;
          //background-size: cover;//
          */
  
          
          
  
  
        }
        .panel.white1 {
          background-color: #FFFFGG;
          height: auto
  
        }
      `;
        
 
        const s = {
            color: 'rgb(150, 148, 146)'
        }
       
        const Language = () => {
            if(this.state.fin){
                return(
                    <div>
                        <p className="w3-opacity"></p>
                        <p className="w3-justify"> 
                        Pekka Parviainen on helsinkiläinen kuvataiteilija jonka tuotannossa keskeiset teemat ovat luonto itsessään ja ihmisen luontosuhde. <br></br>Monissa teoksissa luontoaiheet ovat lähtökohtana, mutta aiheen käsittely on hyvin vapaata ja teos vaikuttaa lähes abstraktilta.<br></br><br></br> Parviainen työskentelee pääasiassa taidemaalarina, mutta grafiikalla on kautta linjan ollut tärkeä asema hänen tuotannossaan. Grafiikan tekeminen on vaikuttanut suuresti myös maalauksiin ja välillä erilaiset tekniikat lähes sulautuvat toisiinsa.</p>
                    </div> 
                     )
            } else {
                return(
                     <div>    
                        <p className="w3-opacity"></p>
                        <p className="w3-justify"> 
                          Pekka Parviainen is a Helsinki-based painter and graphic artist. He works with oil and tempera painting and lithographic techniques.<br></br>
  
                          The two main themes in Parviainen's work are Nature itself and man's relationship to it. Nature is seen as both fragile and powerful at the same time.<br></br> For Parviainen, the natural environment provides an endless source of forms as a starting point for his art. The subject is often treated freely and the level of detail is reduced for clarity.
                        </p>
                     </div>
                )
            }
        }
      

      
        return(

          
      <div className="">
      
       <br></br>
        <div className="bio-matsku container1">
            
            
            <div className="bioText" style={{ padding: "15px", width: "100%"}}>
             
              <h3 className="w3-wide" style={s}><br></br></h3>
             
              <Language />
             
              <button type="button" className="btn btn-sm-dark" onClick={this.vaihada} >
                 En
              </button>
             
            </div>
            <div className="bio-kuva">
             
                <img src={image1} alt="Pekka Parviainen Bio" width={"100%"}  />
       
           </div>
           <br></br>
       </div>
       <br></br>
       
      
      
       
       </div>
     

        )
    }
}


export default AboutMe;