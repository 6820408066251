
import React from 'react'
import './FirstPage.css'
import AboutMe from '../component/AboutMe';
//import Jumbo from '../component/jumbotron'
import Jumbo2 from '../component/jumbotron2'
import axios from 'axios';
import Shop from '../component/Shop';
import FlipCard from '../component/flipCard';
import k from '../et_cetera/samsara.jpeg';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import { Controller, Scene } from 'react-scrollmagic';
import styled from 'styled-components';
import Helmet from 'react-helmet';
//const Url = "http://localhost:1337/Ns";
//const urlAmazon = "http://52.18.193.28/Ns";
const secureUrlAm = "https://admin.pekkaparviainen.com/Ns"

class Firstpage extends React.Component {
    constructor(props) {
       super(props)
         this.state = {
           news: null,
           deviceWidht: 700,
           mobileSocial: null,
           newsText: null,
           newImage2: null,
           newImage3: null


       }
    }
    async componentDidMount() {
      try {
        const res = await axios.get(secureUrlAm)
        console.log(res.data, res.data.map(x => x.setImage.url).toString())
        this.setState({ newsText: res.data })
        this.setState({ news: res.data.map(x => x.setImage.url).toString() })
        this.setState({ newImage2: res.data.map(x => x.kuva1.url).toString()})
        this.setState({ newImage3: res.data.map(x => x.kuva2.url).toString()})
        console.log("Tuleeko mitä",  res.data, this.state.newImage3)

      } catch(err) {
        console.log(err)
      }
      const device = window.innerWidth
      //console.log("hello from component ")
      this.setState({ deviceWidht: device })
      this.setState({ mobileSocial: document.getElementById("mobile-social") });
      
     //this.setState(res.data.filter(x => x.setShow === true))
      
    } 

    render() {

      const SectionWipesStyled = styled.div`
      overflow: hidden;
      .panel {
        height: 100vh;
        width: 100%;
        text-align: center;
      }
      
      .panel span {
        position: relative;
        display: block;
        overflow: visible;
        top: 50%;
        font-size: 80px;
      }
      
      .panel.blue {
        
        background: url(${k}) no-repeat center;
        
        
      }
     
      
      .panel.turqoise {
        
        background-color: #FFF;
        height: auto;
        width: 100%;
      }
      
      .panel.green {
        background-color: #FFFFFF;
        height: 100vh
      }
      
      .panel.bordeaux {
        background-color: #953543;
      }
      .panel.white {
        background-color: #FFFFFF;
        height: auto;
        /*
        background: url(${k}) no-repeat;
        background-position: center center;
        //background-size: cover;//
        */

        
        


      }
      .panel.white1 {
        background-color: #FFFFFF;
        height: 100vh
        
      }
      .panel.shop {
        background-color: #FFFFFF;
        height: 100vh
      }
      .panel.footer {
        height: 30vh
        background-color: #000000;
      }
      .panel.whiteSpace {
        height: auto
      }
    `;

     const settings = {
       dots: true,
       fade: true,
       infinite: true,
       speed: 500,
       slidesToShow: 1,
       slidesToScroll: 1,
       autoplay: true,
       autoplaySpeed: 5000,
    
      };

      const News = () => {
        if (this.state.news){
          return(
            <div className="news">

            <div className="images-to-news">
             <div className="news-box-image">
                 <Slider {...settings}>
                   <div>
                       <img src={`https://admin.pekkaparviainen.com${this.state.news}`}alt="Pekka Parviainen Galleria kuva" width={"100%"}
                       style={{maxHeight: "700px", "background-size": "cover",  
                      'background-position': 'center center'}}
                      />
                   </div>
                   <div>
                      <img src={`https://admin.pekkaparviainen.com${this.state.newImage2}`}alt="Pekka Parviainen näyttely / galleria kuva" width={"100%"}
                      style={{maxHeight: "700px", "background-size": "cover",  
                      'background-position': 'center center'}}
                      />
                   </div>
                   <div>
                     <img src={`https://admin.pekkaparviainen.com${this.state.newImage3}`}alt="Pekka Parviainen Galleria" width={"100%"}
                      style={{maxHeight: "700px", "background-size": "cover",  
                      'background-position': 'center center'}}
                      />
                  </div>
                 
                 
                 </Slider>  

              {/*
              <img src={`http://52.18.193.28${this.state.news}`}alt="" width={"100%"}
              style={{maxHeight: "700px", "background-size": "cover",  
              'background-position': 'center center'}}
              />

              */}
              <div className="news-box-text">
               <h4>{this.state.newsText[0].otsikko}</h4>
               <p>{this.state.newsText[0].kuvaus}</p>
            </div>

             </div>
             

           
      
        
          
            </div> 
           </div>
          )

        } else {
          return (
            <div>
               <h3 className="text-center">Loading news ....</h3>
            </div>
          )
        }
      }
      const MobileOrDesktop = () => {
        {/*
        
        if (this.state.deviceWidht < 800 ) {
            
          return(
            
            <div className="contain">
            <div className="new-bio-backround container1">
            <div className="new-bio">         
               <div className="new-bio-image">
                <img style={{ height: "400px"}} src={k} alt="backround-pekka" ></img>
               </div>
               <div className="testee w3-wide">
               <h1>Hola Hola</h1>
            
               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eget quam eleifend, vestibulum nunc in, finibus tellus. Suspendisse commodo placerat faucibus. Phasellus eu interdum quam. Etiam at vestibulum felis. Duis metus lorem, sodales tempor aliquet ac, ultrices nec libero. Curabitur laoreet gravida tempus. Nunc at lorem quis magna volutpat egestas. Vestibulum dignissim, nisl a bibendum egestas, leo erat placerat dui, vitae sollicitudin leo lectus id nisl. Nam non mattis est. Sed nec tempor sem. Etiam lobortis mi elit, ut mattis arcu aliquet vitae. Nunc eleifend feugiat dignissim. Sed id tellus vel nunc commodo auctor non et erat.</p>
            
              </div>
             </div>
             </div>           

              <FlipCard />
              
              <br></br>
              <div style={{ backgroundColor: "#EFEFEF"}}>
                 <AboutMe />
              </div>
                <br></br>
               
                <br></br>
                <hr></hr>
              
               <div> 
                <News />
               </div> 

                <br></br>
                <Shop />
            </div>
          )
        } else {
          
          document.getElementById("mobile-social").style.display = "none"
          
        */}
          return(
            <div>
         
        
           <div className="new-bio-backround">
                 <AboutMe /> 
             </div>           
          
   
             <FlipCard  />
              
              <SectionWipesStyled className="container1">
              <Controller globalSceneOptions={{ triggerHook: 'onLeave' }}>
                <Scene pin>
                  <div className="panel whiteSpace"><span style={{ top: "0px", fontSize: "50px"}}><br></br></span></div>
                </Scene>
                <Scene pin>
               
                  <div className="panel turqoise">
                  <hr></hr>
                
                  <div className="new-bio-desk container1">
                  <div className="testee">
                    <h4 className="lause-firstpage">
                    ”Omnis ars imitatio naturae est ”
                    -Seneca</h4>
                   <br></br>
                   <div style={{ textAlign: "center" }}>
                   <div className="new-bio-social">
                     <a href="https://www.facebook.com/Pekka-Parviainen-Visual-Artist-1515775352007206/" className="fa fa-facebook"></a>
                     <a href="https://www.flickr.com/photos/pparvi" className="fa fa-flickr"></a>
                     <a href="https://www.youtube.com/channel/UCQtPt0WWVwQUii8NkcrKKFQ" className="fa fa-youtube"></a>
                     <a href="https://www.instagram.com/pekka_parviainen_visual_artist/" className="fa fa-instagram"></a>
                   </div>
                   </div>
                   </div>
                   
                   <div className="testee-image">
                    <img src={k} alt="Pekka Parviainen Samsara kuva" ></img>
                   </div>
                   <br></br>
                 </div>
                 <br></br>
                 <br></br>
                 <hr></hr>
                
                  
                  </div>
                </Scene>
                <Scene pin>
                  <div className="panel green">
                  <br></br>

                    <br></br>
                    <News />
                  </div>
                </Scene>
                <Scene pin> 
                  <div className="panel shop">
                    <br></br>
                    <br></br>
                    <Shop />
                  </div>
                </Scene>
               </Controller>
               </SectionWipesStyled> 
              
           </div>
            
          )
        
      }
      /*
       <div>
            <div>
            <img className="imageC" width={"100%"} src={nimmari} alt="" />
            </div>
        </div>    
        
        <div className="oma">
        <div className="row no-gutters">
          
         
            <div className="col-sm-6">
           
               <a href="/home/grafiikka">
                 <img  width={"100%"}  src={testi1}  alt="Null" />
               
                
               </a>
          
           </div>
             <div className="col-sm-6">
           
             <a href="/home/maalaukset">
               <img  width={"100%"}  alt="toka" src={testi2}/>
             </a>
           
        
        </div>
        </div>
        <br></br>
            
        </div>   
            
       

            <div>
            
            <h3 className="omaLinkki"><a href="/home/portfolio">Portfolio</a></h3>
            <br></br>
            <a href="/home/portfolio" >
              <img src={testi3} alt="" width={"100%"} mode="fit"  />
            </a>
              
            </div>

            
           


      */

  
       
       

        return(
        <div className=""> 
        
        <Helmet>
        <meta name="description"
        content="Pekka Parviainen on helsinkiläinen kuvataiteilija jonka tuotannossa keskeiset teemat ovat luonto itsessään ja ihmisen luontosuhde. Parviainen työskentelee pääasiassa taidemaalarina, mutta grafiikalla on kautta linjan ollut tärkeä asema hänen tuotannossaan. </p>
        "
        ></meta>
        <meta name="description" content="Näyttelyt Pekka Parviainen In Summa,
          Diversity 
          Existence, Natura Lux
          "
        ></meta>
        <meta name="description" content="Tulevat näyttelyt Pekka Parviainen Galleriat"></meta>
        <meta name="description" content="Pekka Parviainen Gallery Contancts Helsinki, Järvenpää, Kouvola, Taiko, Grafia 13, Taidemaalarienliitto"></meta>


        </Helmet>
        
        <div style={{ backgroundColor: "#FFFFFF" }}>
           <div className="container1">
          
            <Jumbo2 />
           <br></br> 
           <br></br>
           </div> 
         </div>
        
         {/*
         <SectionWipesStyled>
         
         <Controller globalSceneOptions={{ triggerHook: 'onLeave' }}>
           <Scene pin>
             <div className="panel blue"><span>Panel</span>
             
             </div>
           </Scene>
           <Scene pin>
           <div className="panel green" style={{ textAlign: "center", marginTop: "30px"}}>
           <br></br>
           <h3>Up comming works</h3>
             <p>Something others....</p>
          </div>
           </Scene>
           <Scene pin>
             <div className="panel white1" >  
             
             <FlipCard /> 
           
             </div>
           </Scene>
           <Scene pin>
           <div className="panel white">  
          
           </div>
         </Scene>
           </Controller>
          </SectionWipesStyled>
         */} 
          <MobileOrDesktop />
        
         
           
         
           
            
          
           {/*  <News /> */}
            {/*
            <div className="box">
                <h3 className="text-center">News</h3>
                <img src="http://pekkaparviainen.com/api/files/news" alt="" width={"100%"}
                style={{maxHeight: "550px", "background-size": "cover",  position: 'relative',
                'background-position': 'center center'}}
                />

            </div>
            */}
           
          {/* <Shop /> */}
           
          

         </div>    
    
           
        )
    }
    


}

export default Firstpage;