import React from 'react';
import Helmet from 'react-helmet';
import 'react-image-lightbox/style.css';
import EtCetera from '../et_cetera/et_cetera';
import '../component/Lightbox.css';
import '../component/image1.css';
import Haloo from './testiKuva';

const Etcetera = () => {




    return (
        <div>
          <Helmet>
             <title>Et-cetera - Pekka Parviainen</title>
             <meta name="Keyword" content="Et-Cetera Pekka Parviaianen" ></meta>

          </Helmet>
          <br></br> 
          <h3 className="text-center">Et_Cetera</h3>
          <hr></hr>
         
          <ul className="works">

             <li><a href="/home/exhibitions/natura_lux">Natura</a></li>

             <li><a href="/home/exhibitions/in_summa">In Summa</a></li>
             <li><a href="/home/exhibitions/diversity">Diversity</a></li>
             <li><a href="/home/exhibitions/existence">Existence</a></li>


          </ul>
           <br></br>
           <Haloo 
               kuvat={EtCetera}
               nimi={'Maalaukset'}
           />
           
           
          
           
            <br></br>
           
        </div>
    )
}


export default Etcetera;