
import React from 'react'

import 'react-image-lightbox/style.css';
import Helmet from 'react-helmet';

import Haloo from './testiKuva';

import Testi from '../natura/natura';

import '../component/Lightbox.css'
import '../component/image1.css'


class naturaLux extends React.Component {
    
   

    render() {
      
     

        return(
            <div>

            <Helmet>
              <title>Natura - Pekka Parviainen</title>
              <meta name="description" content="Sanotaan, että vain ihmisellä on luontosuhde, kaikki muut lajit ovat osa luontoa. Vaikka koemme erillisyyttä, olemme riippuvaisia luonnon tasapainosta." ></meta>
              <meta name="Keyword" content="Natura Pekka Parviaianen" ></meta>

            </Helmet>
            <br></br>
            <h3 className="text-center">Natura</h3>
            <hr></hr>

       
            <ul className="works">
               <li><a href="/home/exhibitions/in_summa">In Summa</a></li>
               <li><a href="/home/exhibitions/diversity">Diversity</a></li>
               <li><a href="/home/exhibitions/existence">Existence</a></li>

               <li><a href="/home/exhibitions/etcetera">Et_Cetera</a></li>






            </ul>
            <br></br>
         
             <Haloo 
                 kuvat={Testi}
                 nimi={'Maalaukset'}
             />
             
             
            
             
              <br></br>
             </div>



        )
    }
}

export default naturaLux;