import React , {Component} from 'react';
import './App.css';
import NavBar from './component/Nav';

import Footer from './component/Footer'
import { BrowserRouter as Router, Route, } from 'react-router-dom'
import FirstPage from './component/Firstpage';

import Contact from './component/Contact';
import WebShop from './component/WebShop';
//import Maalaukset from './component/Maalaukset'
//import Grafiikka from './component/Grafiikka';
import Cv from './component/Cv';
import reactGa from 'react-ga';

import Testii from './component/natura_lux';
import InSumma from './component/inSumma';
import Diversity from './component/diversity';
import Existence from './component/existence';
import Etcetera from './component/etcetera';



class App extends Component {
  
  componentDidMount(){
    reactGa.initialize('UA-144482746-1')
    reactGa.pageview(window.location.pathname);
  }
  
  render() {
   
   

 
   

    return(
     <div>
       <Router>

       
        <NavBar />
        

        
           <Route exact path="/home" render={() => <FirstPage />} /> 
          <div className="container1">
           <Route path="/home/cv" render = {(props) => <Cv {...props} fin={true} />} />
           <Route path="/home/contact" render = {() => <Contact />} />
          {/*  <Route path="/home/webshop" render = {() => <WebShop />} /> */}

           <Route path="/home/exhibitions/natura_lux" render = {() => <Testii />} />
           <Route path="/home/exhibitions/in_summa" render={() => <InSumma />} />
           <Route path="/home/exhibitions/diversity" render={() => <Diversity />} />
           <Route path="/home/exhibitions/existence" render={() => <Existence />} />
           <Route path="/home/exhibitions/etcetera" render={() => <Etcetera />} />

           {/*<Route path="/home/grafiikka" render = {() => <Grafiikka />} />*/}

         </div> 
        <Footer />
       
       </Router>
        
   </div>
     
      
    )
  }
}

export default App;