
import React from 'react'

import 'react-image-lightbox/style.css';
import Helmet from 'react-helmet';

import Haloo2 from './testiKuva';

import Testi from '../diversity/diversity';

import '../component/Lightbox.css'
import '../component/image1.css'


class divercity extends React.Component {
    
   

    render() {
      
     

        return(
            <div>
            
            <Helmet>
              <title>Diversity - Pekka Parviainen</title>
              <meta name="Keyword" content="Diversity Pekka Parviaianen" ></meta>

            </Helmet>
            <br></br>
            
            <h3 className="text-center">Diversity</h3>
            <hr></hr>
            <ul className="works">
               <li><a href="/home/exhibitions/natura_lux">Natura</a></li>
               <li><a href="/home/exhibitions/in_summa">In Summa</a></li>

               <li><a href="/home/exhibitions/existence">Existence</a></li>
               <li><a href="/home/exhibitions/etcetera">Et_Cetera</a></li>

            </ul>
            <br></br>
             <Haloo2 
                 kuvat={Testi}
                 nimi={'Maalaukset'}
             />
             
             
            
             
              <br></br>
             </div>



        )
    }
}

export default divercity;