import React, { useState, useEffect } from 'react';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';

import '../component/Lightbox.css';
import '../component/image1.css';


const AllImages = (props) => {
    const [ alleViisi, setViisi ] = useState([])
    const [ yliViisi, setYliviisi ] = useState([])
    const [ Loput, setLoput ] = useState([])
    const [ show, setShow ] = useState(false)
    const [ id, setId ] = useState(false)

    useEffect(() => {
         const pituusKuvat = Math.floor(props.kuvat.length / 3);
         setViisi(props.kuvat.filter(x => x.id <= pituusKuvat));
         setYliviisi(props.kuvat.filter(x => x.id > pituusKuvat  && x.id <= pituusKuvat * 2 ));
         setLoput(props.kuvat.filter(x => x.id > pituusKuvat * 2 ));
    }, [])

    const showModal = pram => e => {
        setShow(true)
        setId(pram.id)
    }

    /*
    const closeModal = () => {
        setShow(false)
    }
    */
    const s = {
        width: "100%",
    }

    const ss = {
         width: "auto",
         height: "auto",       
    }
    if (id > props.kuvat.length){
        setId(0)
    } else if (id === -1){
        setId(props.kuvat.length)
    }
 

    //console.log(props.kuvat.map(x => x.id))
    //console.log(props.nimi)
    //console.log(alleViisi, yliViisi, Loput)

    return(
        <div>
        {show && (
            <div>
             <Lightbox
                mainSrc={props.kuvat.filter(x => x.id === id).map(x => x.imageAdress)}
                imageTitle={props.kuvat.filter(x => x.id === id).map(x => x.name)}
                
                nextSrc={props.kuvat.filter(x => x.id === id).map(x => x.imageAdress)}
                prevSrc={props.kuvat.filter(x => x.id === id).map(x => x.imageAdress)}


                imageCaption={props.kuvat.filter(x => x.id === id).map(x => x.description)}
                onCloseRequest={() => setShow(false)}
                animationDuration={300}
                //imagePadding={55}
                onImageLoadError={() => "Click to continue"}
                onMovePrevRequest={() => 
                    setId(id - 1 )
                }
                onMoveNextRequest= {() =>                      
                     setId(id + 1)
                         
                }
             
             
             
             />
         
         </div>
         )
       }

        <div className="w3-row" id="myGrid" style={ss}>
        </div>
         <div className="row1">
         
         <div className="column" style={{ cursor: "pointer" }} >
         {alleViisi.map(x => 

           <img key={x.id} src={x.imageAdress}  value="kksd"  onClick={showModal({id: x.id })} alt={x.name} style={s} />

         )}
         </div>

         <div className="column" style={{ cursor: "pointer" }} >
         {yliViisi.map(x => 

             <img key={x.id} src={x.imageAdress}  value="kksd"  onClick={showModal({id: x.id })} alt={x.name} style={s} />
           
         )}
         </div>
         <div className="column" style={{ cursor: "pointer" }} >

         {Loput.map(x => 

           <img key={x.id} src={x.imageAdress}  value={x.id}  onClick={showModal({id: x.id })} alt={x.name} style={s} />
            
         
         )}
         </div>
        </div> 

       </div>
    )
}

export default AllImages;