import React from 'react'

import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { } from 'semantic-ui-react'


import kuva1 from '../imagesFronpage/1.jpg'
import kuva2 from '../imagesFronpage/2.jpg'
import kuva3 from '../imagesFronpage/3.jpg'
import kuva4 from '../imagesFronpage/4.jpg'
import kuva5 from '../imagesFronpage/5.jpg'

const Jumbo2 = () => {
  
    const settings = {
        dots: true,
        infinite: true,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
      
    };

    return(
      <div className="jumbo2">
      
       <Slider className="jumbo2" {...settings}>
       <div>
         <img src={kuva1} width="100%" style={{maxHeight: "550px", "background-size": "cover",  position: 'relative',
         'background-position': 'center center'}} alt="Pekka Parviainen kuva"></img>
       </div>
       <div>
       <img src={kuva2} width="100%" style={{maxHeight: "550px", "background-size": "cover",  position: 'relative',
       'background-position': 'center center'}} alt="Pekka Parviainen kuva2"></img>
      </div>
      <div>
       <img src={kuva3} width="100%" style={{maxHeight: "550px", "background-size": "cover",  position: 'relative',
       'background-position': 'center center'}} alt="Pekka Parviainen  kuva 3"></img>
      </div>
      <div>
        <img src={kuva4} width="100%" style={{maxHeight: "550px", "background-size": "cover",  position: 'relative',
         'background-position': 'center center'}} alt="Pekka Parviainen kuva 4"></img>
      </div>
      <div>
      <img src={kuva5} width="100%" style={{maxHeight: "550px", "background-size": "cover",  position: 'relative',
       'background-position': 'center center'}} alt="Pekka Parviaine kuva 5"></img>
     </div>
     
       
       </Slider>
       <div className="jumbo-text" style={{ marginTop: "30px", textAlign: "center"}}>
         <h1 >Pekka Parviainen</h1>
         <h3>Visual Artist</h3>
        
       </div>
       {/*
       <div id="mobile-social" className="new-bio-social-mobile">
          <a href="https://www.facebook.com/Pekka-Parviainen-Visual-Artist-1515775352007206/" className="fa fa-facebook"></a>
          <a href="https://www.flickr.com/photos/pparvi" className="fa fa-flickr"></a>
          <a href="https://www.youtube.com/channel/UCQtPt0WWVwQUii8NkcrKKFQ" className="fa fa-youtube"></a>
          <a href="https://www.instagram.com/pekka_parviainen_visual_artist/" className="fa fa-instagram"></a>
      </div>
       */}
      </div>
    )
}


export default Jumbo2;