

const Testi = [
    {
      "id": 1,
      "name": "Existence",
      "description": "2019, tempera, 167x191 cm",
      "imageAdress": require('../existence/existence.jpeg')
    },
    {
      "id": 2,
      "name": "Mimesis",
      "description": "2019, tempera, 167x191 cm",
      "imageAdress": require('../existence/mimesis.jpeg')
    },
    {
      "id": 3,
      "name": "Messenger",
      "description": "2019, tempera, 167x191 cm",
      "imageAdress": require('../existence/messenger.jpeg')
   },
   {
      "id": 4,
      "name": "Requiem",
      "description": "2019, tempera, 140x130 cm",
      "imageAdress": require('../existence/requiem.jpeg')
   },
  
   {
      "id": 5,
      "name": "In Existence",
      "description": "2019, tempera, 100x130 cm",
      "imageAdress": require('../existence/in_existence.jpeg')
   },
   {
      "id": 6,
      "name": "They see us IX",
      "description": "2019, tempera, 81x61 cm",
      "imageAdress": require('../existence/they_see_us_ix.jpeg')
   },
   {
      "id": 7,
      "name": "They see us VI",
      "description": "2018, tempera, 81x61 cm",
      "imageAdress": require('../existence/they_see_us_VI.jpeg')
   },
   {
      "id": 8,
      "name": "They see us VII",
      "description": "2019, tempera, 81x61 cm",
      "imageAdress": require('../existence/They_see_us_VII.jpeg')
   },
   {
      "id": 9,
      "name": "Entity",
      "description": "2019, tempera, 100x130 cm",
      "imageAdress": require('../existence/entity.jpeg')
   },
   {
      "id": 10,
      "name": "Spiral",
      "description": "2019, lyijykynä, 95x111 cm",
      "imageAdress": require('../existence/spiral.jpeg')
   },
   {
      "id": 11,
      "name": "Verso",
      "description": "2018, lyijykynä, 107x81,5 cm",
      "imageAdress": require('../existence/verso.jpeg')
   },
   {
      "id": 12,
      "name": "Voyage I",
      "description": "2018, lyijykynä, 50x78 cm",
      "imageAdress": require('../existence/voyage_I.jpeg')
   },
   {
      "id": 13,
      "name": "Voyage II",
      "description": "2018, lyijykynä, 50x78 cm",
      "imageAdress": require('../existence/voyage_II.jpeg')
   },
   {
      "id": 14,
      "name": "Voyage III",
      "description": "2019, lyijykynä, 50x78 cm",
      "imageAdress": require('../existence/voyage_III.jpeg')
   },
   {
      "id": 15,
      "name": "Here Today II",
      "description": "2018, lyijykynä, 65x50 cm",
      "imageAdress": require('../existence/here_todayII.jpeg')
   },
   {
      "id": 16,
      "name": "My Favourite Things",
      "description": "2018, lyijykynä, 50x78 cm",
      "imageAdress": require('../existence/my_favourite_things.jpeg')   
   },
   {
      "id": 17,
      "name": "Origo",
      "description": "2019, lyijykynä, 50x78 cm",
      "imageAdress": require('../existence/origo.jpeg')   
   },
   {
      "id": 18,
      "name": "Here Today I",
      "description": "2018, lyijykynä, 41x61 cm",
      "imageAdress": require('../existence/here_today_I.jpeg')   
   },
   {
      "id": 19,
      "name": "Interconnected",
      "description": "2018, lyijykynä, 92x111 cm",
      "imageAdress": require('../existence/interconnected.jpeg')   
   },
   {
      "id": 20,
      "name": "Interdependence",
      "description": "2018, lyijykynä, 95x111 cm",
      "imageAdress": require('../existence/interdependence.jpeg')   
   },
   {
      "id": 21,
      "name": "Galleria Bronda 1",
      "description": "Existence -näyttely, 2020, Helsinki",
      "imageAdress": require('../existence/bronda1.jpeg')   
   },
   {
      "id": 22,
      "name": "Galleria Bronda 2",
      "description": "Existence -näyttely, 2020, Helsinki",
      "imageAdress": require('../existence/bronda2.jpeg')   
   },
   {
      "id": 23,
      "name": "Galleria Bronda 3",
      "description": "Existence -näyttely, 2020, Helsinki",
      "imageAdress": require('../existence/bronda3.jpeg')   
   },
   {
      "id": 24,
      "name": "Galleria Bronda 4",
      "description": "Existence -näyttely, 2020, Helsinki",
      "imageAdress": require('../existence/bronda4.jpeg')
}
   
]


export default Testi;