import React from 'react';
import './flipCard.css';

import natura_lux from '../natura/Gaia.jpeg'
import in_summa from '../in_summa/mirage1.jpeg'
import diversity from '../diversity/aarni.jpeg';
import exsistence from '../existence/messenger.jpeg';


class FlipCard extends React.Component {

    



    render() {
     
        return(
       

         <div className="backForCard" style={{ backgroundColor: "white" , height: "auto"}}>
          
            <div  className="cardFlex container1" style={{ height: "auto" }}>
           

         
            
                   
            
             <br></br>
             <div class="flip">
                <div class="front" style={{ backgroundImage: 'url('+natura_lux+')' }} alt="Pekka Parviainen Natura Lux">
                   <h4 class="text-shadow">Natura</h4>
                </div>
                <div class="back">
                   <h2>Natura</h2>
                   <p><strong>2012-2014</strong></p>
                   <p>Natura on kunnianosoitus luonnon kauneudelle, arvolle ja voimalle. Luonto – mestarien mestari – on pysyvästi ajankohtainen.</p>
                   <button onClick={() => window.location.href = "/home/exhibitions/natura_lux" } className="btn btn-light">KATSO</button>
                </div>
            </div>
            
         
         

            <div class="flip">
              <div class="front" style={{ backgroundImage: 'url('+diversity+')' }} alt="Pekka Parviainen Diversity">
                <h4 class="text-shadow">Diversity</h4>
              </div>
               <div class="back">
                 <h2>Diversity</h2>
                 <p><strong>2016-2018</strong></p>
                 <p>Elämä luonnollisessa ekosysteemissä rakentuu yksilöiden kilpailun sijasta yhteisön varaan. Eliöt muodostavat kudelman kaltaisen kokonaisuuden, emmekä tiedä, kuinka paljon yksittäisiä solmuja voidaan menettää, ennen kuin kokonaisuus rikkoontuu. Monimuotoisuus on elämän keino varmistaa jatkuvuus kaikissa olosuhteissa.</p>
                 <button onClick={() => window.location.href = "/home/exhibitions/diversity" }  className="btn btn-light">KATSO</button>              
                 </div>
            </div>

            <div class="flip">
             <div class="front" style={{ backgroundImage: 'url('+in_summa+')'}} alt="Pekka Parviainen In Summa" >
               <h4 class="text-shadow">In Summa</h4>

             </div>
             <div class="back">
               <h2>In Summa</h2>
               <p><strong>2014-2016</strong></p>
               <p>Sanotaan, että vain ihmisellä on luontosuhde, kaikki muut lajit ovat osa luontoa. Vaikka koemme erillisyyttä, olemme riippuvaisia luonnon tasapainosta. Mikä vahingoittaa ympäristöä, vahingoittaa myös meitä itseämme.</p>
               <button onClick={() => window.location.href = "/home/exhibitions/in_summa"} className="btn btn-light">KATSO</button>
            </div>
            </div>
            
            <div class="flip">
            <div class="front" style={{ backgroundImage: 'url('+exsistence+')' }} alt="Pekka Parviainen Exsistense">
            <h4 class="text-shadow">Existence</h4>
            </div>
            <div class="back">
              <h2>Existence</h2>
              <p><strong>2018-2020</strong></p>
              <p>Sadan vuoden kuluttuakin on oltava perhosia,
              muuttolintujen on keväällä palattava muuttoretkiltään.
              Katseelta suojassa harvinainen kasvi nousee yhä maan pinnasta.</p>
              <button onClick={() => window.location.href = "/home/exhibitions/existence" }  className="btn btn-light">KATSO</button>
            </div>
           </div>
            <br></br>
            
            
          
            
           {/*
           <div class="card1">
           <div  style={{ backgroundImage: 'url('+grafiikka+')' }} class="card-front"></div>
          
           <div class="card-back">
              <div class="social-icons">
                  <a href="#" class="fa fa-facebook" aria-hidden="true"></a>
                 
              </div>
           </div>
          </div>
          <div class="card1">
           <div  style={{ backgroundImage: 'url('+grafiikka+')' }} class="card-front"></div>
          
           <div class="card-back">
              <div class="social-icons">
                  <a href="#" class="fa fa-facebook" aria-hidden="true"></a>
                 
              </div>
           </div>
          </div>
           */}
          </div>




         </div>
      
         
         
        )
    }
}


export default FlipCard;